/* StickySideNav */
.active::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -14px;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 7px solid #1c1c1c;
  transform: translateY(-50%);
}

/* LessonPage, ChangelogPage, JobPage (LG, XL) */
@media only screen and (min-width: 1024px) {
  .grid {
    grid-template-columns: 0.35fr 1fr !important; /* Important added to prevent Tailwind overwrite */
  }
}

/* UpgradePage */
@media only screen and (max-width: 1525px) {
  .upgrade-image {
    display: none;
  }
}

/* PricingSelect (XS, SM, MD) */
@media only screen and (max-width: 1023px) {
  .scroll-snap-parent {
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
  }

  .scroll-snap-child {
    width: calc(100% - 6rem);
    flex: 0 0 calc(100% - 6rem);
    scroll-snap-align: center;
  }

  /* Hack to center the last child */
  .scroll-snap-parent::after {
    content: "\00A0";
  }
}

/* PricingSelect (XS) */
@media only screen and (max-width: 639px) {
  .scroll-snap-child {
    width: calc(100% - 4.5rem);
    flex: 0 0 calc(100% - 4.5rem);
  }
}
