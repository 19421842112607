.bf-plan-gradient-lifetime {
  background: linear-gradient(82deg, #31256f 0%, #4f429c 15%, #b44893 60%, #f16370 90%, #fcb76f 100%);
}

.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-color: #828282;
  transform: rotate(-30deg);
}

.lifetime-background-gradient-select {
  background: linear-gradient(90deg, #31256f 0%, #4f429c 50%, #b44893 100%);
}

.lifetime-background-gradient-select:hover {
  background: linear-gradient(90deg, #31256f 0%, #4f429c 30%, #b44893 80%);
}

.lifetime-background-gradient-publish {
  background: linear-gradient(90deg, #da4250 0%, #f16370 45%, #b44893 100%);
}

.lifetime-background-gradient-publish:hover {
  background: linear-gradient(90deg, #da4250 0%, #f16370 30%, #b44893 80%);
}

.lifetime-box-gradient-select {
  background: linear-gradient(45deg, #31256f 0%, #4f429c 45%, #b44893 100%);
}

.lifetime-info-button-gradient {
  background: linear-gradient(45deg, #31256f 0%, #4f429c 20%, #b44893 50%, #f16370 90%, #fcb76f 100%) !important;
}

.lifetime-info-button-gradient:hover {
  background: linear-gradient(45deg, #31256f 0%, #4f429c 15%, #b44893 40%, #f16370 60%, #fcb76f 100%) !important;
}

.select-plan-background-gradient {
  background: linear-gradient(23deg, #202020 34.68%, #000 80.01%), linear-gradient(0deg, #1c1c1c 0%, #1c1c1c 100%),
    #d9d9d9;
}
