@import 'swiper/swiper.scss';
@import 'swiper/components/navigation/navigation.scss';
@import 'tailwindcss/base';

/* Base */
@import './fonts';
@import './globals';
/* /Base */

@import 'tailwindcss/components';

/* Component */
@import '@narrative-software/narrative-web-ui/dist/Pricing/styles.css';
@import '@narrative-software/narrative-web-ui/dist/Carousel/styles.css';
/* /Component */

@import 'tailwindcss/utilities';

/* Utility */
@import './material-overrides.css';
/* /Utility */

@import './bf-custom-backgrounds.css';
