/* TextField */
body .MuiFormLabel-root {
  font-family: inherit;
  letter-spacing: inherit;
  color: #828282;
}

body .MuiFormLabel-root.Mui-disabled {
  color: #828282;
}

body .MuiFormLabel-root.Mui-focused:not(.Mui-error) {
  color: #1c1c1c;
}

body .MuiInputBase-root {
  font-family: inherit;
  letter-spacing: inherit;
  color: #1c1c1c;
}

body .MuiInputBase-root.Mui-disabled {
  color: #828282;
}

body .MuiInputBase-input:-webkit-autofill {
  box-shadow: 0 0 0 30px #f5f5f3 inset !important;
}

body .MuiInput-underline::before {
  border-bottom-color: #828282;
}

body .MuiInput-underline:hover:not(.Mui-disabled)::before {
  border-bottom-color: #1c1c1c;
  border-bottom-width: 1px;
}

body .MuiInput-underline.Mui-disabled::before {
  border-bottom-color: #bdbdbd;
  border-bottom-style: solid;
}

body .MuiInput-underline::after {
  border-bottom-color: #1c1c1c;
  border-bottom-width: 1px;
}

/* Select */
body .MuiSelect-icon {
  color: #bdbdbd;
}

body .MuiSelect-icon.Mui-disabled {
  color: transparent;
}

/**
 * Photography Awards 2022
 */

/* TextField */
body .awards-entry-form .MuiFormLabel-root:not(.Mui-error) {
  color: #bdbdbd;
}

body .awards-entry-form .MuiFormLabel-root.Mui-disabled {
  color: #bdbdbd;
}

body .awards-entry-form .MuiFormLabel-root.Mui-focused:not(.Mui-error) {
  color: #bdbdbd;
}

body .awards-entry-form .MuiInputBase-root {
  color: #ffffff;
}

body .awards-entry-form .MuiInputBase-root.Mui-disabled {
  color: #bdbdbd;
}

body .awards-entry-form .MuiInput-underline::before {
  border-bottom-color: #bdbdbd;
}

body .awards-entry-form .MuiInput-underline:hover:not(.Mui-disabled)::before {
  border-bottom-color: #ffffff;
}

body .awards-entry-form .MuiInput-underline.Mui-disabled::before {
  border-bottom-color: #bdbdbd;
}

body .awards-entry-form .MuiInput-underline:not(.Mui-error)::after {
  border-bottom-color: #ffffff;
}

/* Slider */
body .MuiSlider-thumb {
  width: 19px;
  height: 19px;
  margin-top: -8px;
  margin-left: -6px;
  background-color: #1c1c1c;
  border: 3px solid white;
  padding: 3px;
  outline: 0;
  position: relative;
}

body .MuiSlider-thumb::after {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  bottom: -5px;
  right: -5px;
  border-radius: 50%;
  border: 2px solid #1c1c1c;
}

body .MuiSlider-rail {
  background-color: #e0e0e0;
  height: 3px;
}

body .MuiSlider-track {
  background-color: #1c1c1c;
  height: 3px;
}
