@font-face {
  font-family: "Graphik";
  src:
    url("/fonts/Graphik-Thin.woff2") format("woff2"),
    url("/fonts/Graphik-Thin.woff") format("woff"),
    url("/fonts/Graphik-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src:
    url("/fonts/Graphik-ThinItalic.woff2") format("woff2"),
    url("/fonts/Graphik-ThinItalic.woff") format("woff"),
    url("/fonts/Graphik-ThinItalic.otf") format("opentype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src:
    url("/fonts/Graphik-Extralight.woff2") format("woff2"),
    url("/fonts/Graphik-Extralight.woff") format("woff"),
    url("/fonts/Graphik-Extralight.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src:
    url("/fonts/Graphik-ExtralightItalic.woff2") format("woff2"),
    url("/fonts/Graphik-ExtralightItalic.woff") format("woff"),
    url("/fonts/Graphik-ExtralightItalic.otf") format("opentype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src:
    url("/fonts/Graphik-Light.woff2") format("woff2"),
    url("/fonts/Graphik-Light.woff") format("woff"),
    url("/fonts/Graphik-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src:
    url("/fonts/Graphik-LightItalic.woff2") format("woff2"),
    url("/fonts/Graphik-LightItalic.woff") format("woff"),
    url("/fonts/Graphik-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src:
    url("/fonts/Graphik-Regular.woff2") format("woff2"),
    url("/fonts/Graphik-Regular.woff") format("woff"),
    url("/fonts/Graphik-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src:
    url("/fonts/Graphik-RegularItalic.woff2") format("woff2"),
    url("/fonts/Graphik-RegularItalic.woff") format("woff"),
    url("/fonts/Graphik-RegularItalic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src:
    url("/fonts/Graphik-Medium.woff2") format("woff2"),
    url("/fonts/Graphik-Medium.woff") format("woff"),
    url("/fonts/Graphik-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src:
    url("/fonts/Graphik-MediumItalic.woff2") format("woff2"),
    url("/fonts/Graphik-MediumItalic.woff") format("woff"),
    url("/fonts/Graphik-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src:
    url("/fonts/Graphik-Semibold.woff2") format("woff2"),
    url("/fonts/Graphik-Semibold.woff") format("woff"),
    url("/fonts/Graphik-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src:
    url("/fonts/Graphik-SemiboldItalic.woff2") format("woff2"),
    url("/fonts/Graphik-SemiboldItalic.woff") format("woff"),
    url("/fonts/Graphik-SemiboldItalic.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src:
    url("/fonts/Graphik-Bold.woff2") format("woff2"),
    url("/fonts/Graphik-Bold.woff") format("woff"),
    url("/fonts/Graphik-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src:
    url("/fonts/Graphik-BoldItalic.woff2") format("woff2"),
    url("/fonts/Graphik-BoldItalic.woff") format("woff"),
    url("/fonts/Graphik-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}
